.bmiSection {
    @apply
    border-t
    border-decorators-subtle
    py-100
    border-solid
    flex
    justify-between
    items-center
    gap-050;
}