.loadingWrap {

    @apply py-500;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.loadingInline {
        display: inline-block;
    }
}

.loadingFullScreen {
    position: absolute;
    inset: 0;
}
