.attention {
    @apply type-heading type-090;

    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    width: 100%;
    padding: theme('spacing.100');
    border-width: 2px;
    border-style: solid;
    border-radius: theme('borderRadius.100');
    line-height: theme('lineHeight.200');

    svg {
        flex-shrink: 0;
    }
}

.info {
    border-color: theme('colors.status.info.dark');
    background-color: theme('colors.status.info.subtle');
}

.warning {
    border-color: theme('colors.status.warning.dark');
    background-color: theme('colors.status.warning.subtle');
}

.error {
    border-color: theme('colors.status.error.dark');
    background-color: theme('colors.status.error.subtle');
}

.success {
    border-color: theme('colors.status.success.dark');
    background-color: theme('colors.status.success.subtle');
}
