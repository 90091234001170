.button {

    span {
        text-decoration: underline;
    }

    &:hover,
    &:focus {

        span {
            text-decoration: none;
        }
    }
}
