button.button {
    @apply type-070;

    display: flex;
    align-items: center;
    gap: theme('fields.spacing.default');
    max-width: 8.75rem;
    padding: theme('spacing.025') theme('spacing.050');
    border: theme('fields.default.border');
    border-radius: theme('fields.borderRadius.small');
    transition: border-color theme('transitionDuration.default') theme('transitionTimingFunction.default');

    &:hover,
    &:focus {
        border-color: theme('colors.actions.secondary');
    }
}