button.option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent !important; /* Important need to override foundry css. */

    &:hover {
        background-color: transparent !important;
    }
}
