.overlay {
    @apply type-heading type-080;

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.90);
    color: theme('colors.content.quiet');
}
