.container {
    @apply flex
        flex-col
        w-full
        py-150
        container-gutters-small
        gap-y-150
        mb-300;

    @container (min-width: theme('screens.md')) {
        @apply container-gutters-medium
            mb-0;
    }
}

.card {
    @apply flex
        flex-col
        w-full
        p-0;
}

.cardBody {
    @apply grid
        grid-cols-1
        w-full
        pb-0
        gap-y-150;

    padding: theme('padding.150') theme('padding.150') theme('padding.200');

    max-width: theme('container.sizes.small');

    @container (min-width: theme('screens.md')) {
        @apply grid-cols-3
            gap-y-150
            gap-x-100
            py-[theme('card.padding.large')];
    }
}

.headerContent {
    @apply flex
        flex-row
        justify-between
        items-start
        w-full;

    @media (min-width: theme('screens.md')) {
        @apply flex
            flex-row
            justify-start
            gap-x-100;
    }
}

.headerColumn {
    @apply flex
        flex-col
        justify-between;

    @container (min-width: theme('screens.md')) {
        @apply justify-start
            gap-x-100;
    }
}

.issue {
    @apply flex
        flex-col
        gap-y-[theme('spacing.050')]
        relative
        items-start
        justify-between
        w-full
        p-150
        pb-0;

    @container (min-width: 375px) {
        @apply flex
            flex-row
            justify-between
            items-center;
    }

    @container (min-width: theme('screens.md')) {
        @apply container-gutters-medium;
    }
}

.error {
    @apply flex
        flex-row
        items-center
        justify-between
        gap-x-050;
}

.estimatedDelivery {
    @apply flex
        flex-col
        gap-y-100;
}

.deliveryDetails {
    @apply flex
        flex-col
        order-2
        gap-y-100;

    @container (min-width: theme('screens.md')) {
        @apply col-span-1;
    }
}

.billingMethod {
    @apply flex
        flex-col
        order-4
        gap-y-050;
}

.orderSummary {
    @apply flex
        flex-col
        w-full
        px-100;
}

.orderSummaryHeading {
    @apply hidden;

    @container (min-width: theme('screens.md')) {
        @apply block;
    }
}

.orderSummaryDetails {
    @apply px-100;
    
    padding-bottom: theme('padding.150');
}

.item {
    @apply grid
        grid-cols-[3fr,1fr]
        w-full
        py-075
        gap-y-050;

    border-bottom: theme('card.bordered.border');
    border-style: dotted;

    &:last-child {
        border-bottom: none;
    }

    @container (min-width: theme('screens.md')) {
        @apply grid-cols-3
            items-center
            gap-x-100
            py-150
            justify-between;
    }
}

.treatment {
    @apply flex items-center gap-x-050 order-1;
}

.image {
    @apply w-[theme('size.300')]
        h-[theme('size.300')];
}

.consultation {
    @apply flex
        flex-col
        order-3
        gap-y-025;

    @container (min-width: theme('screens.md')) {
        @apply order-3
            gap-y-025;
    }
}

.doctor {
    @apply flex
        items-center
        gap-x-050;
}

.status {
    @apply flex
        justify-end
        items-center
        order-4;
    @container (min-width: theme('screens.md')) {
        @apply order-none;
    }
}

.noConsultation {
    @apply order-3;
}

.price {
    @apply flex
        flex-col
        justify-around
        items-end
        gap-100
        order-2
        row-span-2;

    @container (min-width: theme('screens.md')) {
        @apply order-4 flex-row justify-end items-center;
    }
}

.orderViewHeader {
    border-bottom: 1px solid theme('colors.decorators.subtle');
    padding: theme('padding.150');

    @media screen and (min-width: theme('screens.md')) {
        display: flex;
        gap: 1.5rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.orderViewActions {
    @apply pt-100 md:pt-0 shrink-0;
    flex-wrap: wrap;
    display: flex;

    > div {
        flex-wrap: wrap;
        flex-grow: 1;
        row-gap: theme('spacing.100');
    }

    @container (min-width: theme('screens.md')) {
        flex-grow: 0;
        padding-bottom: 0;
        justify-content: flex-end;

        > div {
            flex-wrap: nowrap;
        }
    }
}

.actions {

    display: flex;
    justify-content: space-between;

    @media screen and (min-width: theme('screens.md')) {
        flex: 0;
        justify-content: flex-end;
        gap: theme('spacing.100') theme('spacing.100');
        padding-bottom: 0;
    }
}

.hr {
    @apply py-150
        container-gutters-small;

    &.mobile {
        @apply block;

        @container (min-width: theme('screens.md')) {
            @apply hidden;
        }
    }
}

.summaryDetailsHr {
    @apply py-150;

    hr {
        @apply border-dotted;
    }
}

.summaryDetailsTotal {
    @apply py-150;
}
