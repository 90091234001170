.fieldValid {

    input, select {

        background: theme('fields.valid.background');
        border: theme('fields.valid.border');
        opacity: theme('fields.valid.opacity');
        fill: theme('fields.valid.textColor');
    }
}

.fieldInvalid {

    input, select {

        background: theme('fields.error.background');
        border: theme('fields.error.border');
        opacity: theme('fields.error.opacity');
        fill: theme('fields.error.textColor');
    }
}
