.item {
    position: relative;
    padding: theme('spacing.075');
    border-radius: theme('card.borderRadius.default');
    transition-property: height, opacity;
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.easeIn');
    background-color: theme("colors.layers.foreground");
}

.image {
    object-fit: contain;
    flex-shrink: 0;
    max-height: 3rem;
}

.unavailableImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    border: theme('card.bordered.border');

    svg {
        width: 50%;
        height: 50%;
    }
}
