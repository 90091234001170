.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    background-color: #FFF;
    opacity: 0;
    transform: translateY(-0.5rem);
    pointer-events: none;
    transition-property: opacity, transform;
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.easeIn');
    padding: theme('padding.075');
    border-radius: theme('card.borderRadius.default');

    button {
        @apply type-subheading type-080;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.overlayShow {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.heightAnimation {
    transition-duration: theme('transitionDuration.fast');
    transition-property: height, margin-top, opacity;
    transition-timing-function: cubic-bezier(0.6, 0.1, 0.87, 0.4);
}

.animateOpacity {
    opacity: 0;
    transition: opacity theme('transitionDuration.fast') theme('transitionTimingFunction.easeIn');
    overflow: hidden;
    pointer-events: none;
}

.icon {
    flex-shrink: 0;
    border-radius: 0.25rem;
    border: theme('card.bordered.border');

    img {
        padding: theme('spacing.075');
        box-sizing: content-box;
    }
}
