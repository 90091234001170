.checkbox {

    @apply type-heading type-090;

    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    cursor: pointer;
}

.checkboxDisabled {
    cursor: not-allowed;
    color: theme('colors.content.quiet');
}
