.radio {

    @apply transition-colors duration-medium ease-easeIn;
    display: flex;
    align-items: center;
    justify-content: center;
    width: theme('size.150');
    height: theme('size.150');
    border-radius: theme('radio.borderRadius');
    background-color: theme('radio.unchecked.background');
    border: 1px solid #557C91;

    span {
        width: 1.375rem;
        height: 1.375rem;
        background-color: theme('colors.neutrals.white');
        border-radius: theme('borderRadius.full');
    }
}

.checked {

    background-color: theme('radio.checked.background');

    span {
        width: theme('size.050');
        height: theme('size.050');
        background-color: theme('radio.checked.iconColor');
    }
}

.disabled {
    background-color: theme('colors.decorators.subtle');
}
