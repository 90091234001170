.veriff {
    :global(.veriff-container) {
        height: 100%;

        :global(.veriff-submit) {
            height: 100%;
            text-align: center;

            @media (min-width: theme('screens.md')) {
                width: 12.25rem;
            }
        }
    }
}