.imageQuestion {
    @apply space-y-100;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: theme('spacing.200');
    border: 2px dashed theme('colors.decorators.subtle');
    border-radius: theme('borderRadius.100');
    transition: border-color 0.3s linear;
}

.error {
    border-color: theme('colors.status.error.default');
}

.interactive {
    cursor: pointer;

    &:hover,
    &:focus {
        border-color: theme('colors.layers.strong');
        outline: none;
    }
}

.successMessage {
    max-width: 21.375rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
