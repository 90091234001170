.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: theme('checkbox.borderRadius');
    background-color: theme('colors.neutrals.white');
    border: theme('checkbox.unchecked.border');
}

.checked {
    background-color: theme('checkbox.checked.background');
    border: theme('checkbox.checked.background');
}

.disabled {
    border-color: theme('colors.decorators.subtle');
    background-color: theme('colors.neutrals.white');

    &.checked {
        background-color: theme('colors.decorators.subtle');
    }
}

.animated {

    @apply duration-fast;
}

.instant {
    transition-duration: 0ms;
}
