.select {

    @apply  !w-[180px];

    + img {
        position: absolute;
        top: 50%;
        left: 155px;
        margin: 0;
        transform: translateY(-50%);
        pointer-events: none;
    }

}
