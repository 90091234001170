button.switchWrapper {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 100%;
    background-color: transparent !important; /* Important need to override foundry css. */

    &:hover {
        background-color: transparent !important;
    }
}
