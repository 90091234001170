.progress_bar {
    display: flex;
    width: 100%;
    height: 0.375rem;
    border-radius: 0.25rem;
    background-color: #D8D8D8;
}

.progress_bar__indicator {
    border-radius: 0.25rem;
    transition-property: width, background;
    transition-duration: 0.3s;
    transition-timing-function: linear;

    &.progress_bar__indicator__default {
        background: linear-gradient(-45deg, rgb(22 188 229 / 1) 0%, rgb(20 150 225 / 1) 100%);
    }
}

.background_color__error {
    background-color: theme('colors.status.error.default');
}

.background_color__warning {
    background-color: theme('colors.status.warning.default');
}

.background_color__success {
    background-color: theme('colors.status.success.default');
}

.background_color__accent {
    background-color: theme('colors.content.highlight');
}