.container {

    @apply  flex
        flex-col
        gap-y-150
        w-full;


}

.list {

    @apply  flex
        flex-col
        gap-y-100;
}