.veriff {
    :global(.veriff-submit) {
        @apply button button-primary button-size-default;

        height: auto;
    }

    :global(.veriff-description) {
        display: none;
    }
}
