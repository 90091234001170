.container {
    @apply w-full
        py-150
        space-y-300
        container-gutters-small;

    @container (min-width: 768px) {
        @apply container-gutters-medium;
    }
}

.hr {
    &.mobile {
        @apply block;

        @container (min-width: 768px) {
            display: none;
        }
    }
}

.reordersAndSavedBaskets {
    @apply grid
        gap-300
        grid-cols-1;

    @container (min-width: 768px) {
        @apply grid-cols-2;
    }

    @container (min-width: 1152px) {
        @apply grid-cols-2
            gap-x-150;
    }
}

.subTitle {
    @apply md:text-600 md:leading-600 lg:text-700 lg:leading-700;
}

