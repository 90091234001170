.switchInput {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.375rem;
    background-color: theme('switch.unchecked.background');
    border-radius: 0.9375rem;
    border: theme('border.light');
    transition-delay: 150ms;
    transition: background-color 300ms ease-out;
    vertical-align: top;

    span {
        position: absolute;
        top: 0.2rem;
        left: 0.25rem;
        display: inline-block;
        width: 0.875rem;
        height: 0.875rem;
        background-color: theme('switch.unchecked.iconColor');
        border-radius: theme('borderRadius.full');
        transition-delay: 150ms;
        transition: left 300ms ease-out;
    }
}

.checked {
    background-color: theme('switch.checked.background');

    span {
        left: calc(100% - 1.125rem);
        background-color: theme('switch.checked.iconColor');
    }
}

.disabled {
    background-color: theme('switch.unchecked.background');

    span {
        background-color: theme('switch.disabled.iconColor');
    }
}

