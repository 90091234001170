.card {
    position: relative;
    overflow: hidden;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: theme('spacing.050');
    padding: theme('card.padding.medium');

    @media (min-width: theme('screens.md')) {
        padding: theme('spacing.150');
    }
}

.orderNumber {
    white-space: nowrap;

    &:first-letter {
        text-transform: uppercase;
    }
}

.details {
    display: flex;
    gap: theme('spacing.200');
    flex-direction: column;
    padding: theme('card.padding.medium');

    @media (min-width: theme('screens.md')) {
        flex-direction: row;
        padding: theme('spacing.150');
    }

    & > section {
        &:first-child {
            @media (min-width: theme('screens.md')) {
                width: 240px;
                flex-shrink: 0;
            }
        }

        &:last-child {
            flex-grow: 1;
        }
    }
}
